import { mapActions, mapState } from 'vuex'
import { formatValueCurrency, currencyFormat, textFormatDateDay } from '@/helpers'
import { getTransferences, getBillingTypeBalances } from '@/queries/transferences/transferences.queries'
import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'TTransferences',

  mixins: [ paginationMixin ],

  components: {
    TSideBlock: () => import('@/components/transferences/sideblock.vue'),
  },

  data() {
    return {
      isLoading: false,
      isLoadingSideBlock: false,
      limit: 50,
      billingType: '',
      thead: [
        { title: 'Data', classes: '', key: 'processedAt', order: null },
        { title: '$ Doado', classes: '', key: 'incomeAmount', order: null },
        { title: 'Taxa', classes: '', key: 'totalFee', order: null },
        { title: '$ Transferido', classes: 'right aligned collapsing', key: 'amount', order: null }
      ],
      sideBlockData: [],
      transferences: [],
      routeParams: {
        routeName: 'transferenceTransaction',
        clickToView: true
      }
    }
  },

  computed: {
    ...mapState({
      orderParams: ({ transferences }) => transferences.orderParams,
      metaDataList: ({ transferences }) => transferences.metaDataTransferences
    }),
  },

  methods: {
    ...mapActions('transferences', [ 'setOrderParams', 'setMetaDataTransferences' ]),
    ...mapActions('transference', [ 'setTransferenceData' ]),

    executeSearch ( params = {} ) {
      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }

      this.isLoading = true
      getTransferences( searchParams ).then(res => {
        const { collection, metadata } = res.data.transfersList
        this.transferences = collection
        this.setMetaDataTransferences(metadata)
        this.updateMetadataTable()
        this.isLoading = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.isLoading = false
      })
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.executeSearch()
    },

    mountSearchParams() {
      let params = {}

      if (this.billingType) {
        params.billingType = this.billingType
      }

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    formatData(data) {
      return {
        processedAt: textFormatDateDay(data.processedAt),
        incomeAmount: currencyFormat(data.incomeAmount),
        totalFee: currencyFormat(data.totalFee),
        amount: formatValueCurrency(data.amount)
      }
    },

    getSideblockData() {
      this.isLoadingSideBlock = true
      getBillingTypeBalances().then(res => {

        const { billingTypeWalletGroupsBalances } = res.data

        this.sideBlockData = billingTypeWalletGroupsBalances.map((data) => ({
          icon: 'file-invoice-dollar',
          familyIcon: 'fal',
          paymentMethod: data.name,
          toBeReleased: currencyFormat(data.balanceWaitingFunds),
          avaiable: currencyFormat(data.balanceAvailable),
          billingType: data.billingType
        }))
      }).then(() => {
        this.isLoadingSideBlock = false

        if (this.sideBlockData.length > 0) {
          this.billingType = this.sideBlockData[0].billingType
          this.executeSearch({ billingType: this.billingType })
        }
      }).catch(e => {
        Vue.$log.error('error', e)
        this.isLoadingSideBlock = false
      })
    },

    getLineItem(data) {
      this.setTransferenceData(data)
    }
  },

  mounted() {
    this.billingType = ''
    this.getSideblockData()
  }
}
