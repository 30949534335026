const paginationMixin = {

  data () {
    return {
      total: 0,
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      limit: 10
    }
  },

  methods: {
    prevPage () {
      if (this.currentPage === 1) return
      const page = Number(this.currentPage) - 1
      let params = { page }
      this.executeSearch(params)
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return
      const page = Number(this.currentPage) + 1
      let params = { page }
      this.executeSearch(params)
    },

    goToPage (pageNumber) {
      const page = Number(pageNumber)
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      let params = { page }
      this.executeSearch(params)
    },

    updateMetadataTable () {
      // metaDataList needs to exist where this mixin will be used
      if ( this.metaDataList ) {
        this.totalPages = `${this.metaDataList.totalPages}`
        this.currentPage = `${this.metaDataList.currentPage}`
        this.pagesSelected = `${this.metaDataList.limitValue}`
        this.total = this.metaDataList.totalCount
      }
    }
  }
}

export default paginationMixin
