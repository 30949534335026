import gql from 'graphql-tag'

const query = gql`
  query transferPaymentIntentHistories(
    $transferId: ID!,
    $page: Int = 1,
    $limit: Int = 50,
    $dueDateBegin: ISO8601Date,
    $dueDateEnd: ISO8601Date,
    $paymentMethod: String,
    $status: String,
    $customerName: String,
    $amount: Int,
    $amountOperator: String
    $orderBy: String = "dueDate",
    $order: String = "asc") {
      transferPaymentIntentHistories(
      transferId: $transferId,
      page: $page,
      limit: $limit,
      dueDateBegin: $dueDateBegin,
      dueDateEnd: $dueDateEnd,
      paymentMethod: $paymentMethod,
      status: $status,
      customerName: $customerName,
      amount: $amount,
      amountOperator: $amountOperator
      orderBy: $orderBy,
      order: $order
    ) {
      collection {
        id
        amount
        createdAt
        customerName
        dueDate
        feeAmount
        paymentMethod
        paymentMethodLabel
        paymentIntentId
        state
        stateLabel
        status
        statusLabel
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
}`

async function getPaymentIntents ( params = {} ) {
  const { $api }= Vue.prototype

  let beginDate = ''
  let endDate = ''

  if (params.dueDateBegin && params.dueDateEnd) {

    let { dueDateBegin, dueDateEnd } = params

    dueDateBegin = dueDateBegin.split("/")
    dueDateEnd = dueDateEnd.split("/")
    beginDate = new Date(dueDateBegin[2], dueDateBegin[1] - 1, dueDateBegin[0]).toISOString().split("T")[0]
    endDate =  new Date(dueDateEnd[2], dueDateEnd[1] - 1, dueDateEnd[0]).toISOString().split("T")[0]

    params.dueDateBegin = beginDate
    params.dueDateEnd = endDate
  }

  const result = await $api.query({
      query,
      variables: params
  }).then( res => res )
  .catch( e => { throw e })
  return result;
}

export { getPaymentIntents }
