import gql from 'graphql-tag'

const query = gql`
  query billingTypeWalletGroupsBalances(
    $orderBy: String = "balanceAvailable",
    $order: String = "desc") {
      billingTypeWalletGroupsBalances(
      orderBy: $orderBy,
      order: $order
    ) {
      id
      name
      billingType
      balanceAvailable
      balanceWaitingFunds
      createdAt
      updatedAt
    }
}`

async function getBillingTypeBalances ( params = {} ) {
  const { $api }= Vue.prototype
  const result = await $api.query({
      query,
      variables: params
  }).then( res => res )
  .catch( e => { throw e })
  return result;
}

export { getBillingTypeBalances }
