import gql from 'graphql-tag'

const query = gql`
  query transfersList(
    $page: Int = 1,
    $limit: Int = 50,
    $billingType: String,
    $orderBy: String = "processedAt",
    $order: String = "desc") {
    transfersList(
      page: $page,
      limit: $limit,
      billingType: $billingType,
      orderBy: $orderBy,
      order: $order
    ) {
      collection {
        id
        processedAt
        requestedAt
        amount
        totalFee
        incomeAmount
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
}`

async function getTransferences ( params = {} ) {
  const { $api }= Vue.prototype
  const result = await $api.query({
      query,
      variables: params
  }).then( res => res )
  .catch( e => { throw e })
  return result;
}

export { getTransferences }
