var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "transferences-page" },
    [
      _c("t-page-header", {
        attrs: { title: "Transferências" },
        scopedSlots: _vm._u(
          [
            false
              ? {
                  key: "notifications",
                  fn: function() {
                    return [_c("t-notification")]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            { attrs: { size: "six", customClass: "full-bg" } },
            [
              _c("h1", [_vm._v("Carteiras")]),
              _vm.sideBlockData.length > 0 && !_vm.isLoadingSideBlock
                ? _c(
                    "div",
                    _vm._l(_vm.sideBlockData, function(item, key) {
                      return _c(
                        "div",
                        {
                          key: key,
                          staticClass: "sideblock-master item",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.executeSearch({
                                billingType: item.billingType
                              })
                            }
                          }
                        },
                        [
                          _c("t-side-block", {
                            attrs: {
                              icon: item.icon,
                              familyIcon: item.familyIcon,
                              color: item.color,
                              paymentMethod: item.paymentMethod,
                              toBeReleased: item.toBeReleased,
                              avaiable: item.avaiable,
                              dueDate: item.dueDate
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    [_c("t-side-block", { attrs: { isLoading: true } })],
                    1
                  )
            ]
          ),
          _c(
            "t-page-content",
            { attrs: { size: "ten" } },
            [
              _vm.transferences.length === 0 && !_vm.isLoading
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma transferência",
                      subheader: "Não há transferências"
                    }
                  })
                : _c("tui-table", {
                    attrs: {
                      isLoading: _vm.isLoading,
                      headers: _vm.thead,
                      dataList: _vm.transferences,
                      dataFormatter: _vm.formatData,
                      routeParams: _vm.routeParams,
                      sortable: ""
                    },
                    on: { setItemEmit: _vm.getLineItem, orderBy: _vm.orderBy }
                  }),
              _vm.totalPages > 1
                ? _c("t-pagination", {
                    attrs: {
                      btnClickPrev: this.prevPage,
                      btnClickNext: this.nextPage,
                      totalPages: _vm.totalPages,
                      currentPage: _vm.currentPage,
                      pagesSelected: _vm.pagesSelected,
                      pages: [5, 10, 50, 100],
                      goToPage: _vm.goToPage
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }